export default {
    data() {
        return {
            loading: false,
            customerType: [],
            customerCode: '',
            selectedCustomerType: "",
            customerTypeId: "",
            customerID: "",
            selectedDistrict: "",
            selectedVillage: "",
            listDistricts: [],
            listVillages: [],
            selectedDistrictAdd: "",
            filterVillages: [],
            packages: [],
            package_Id: "",
            selectedPackage: "",
            server_errors: {
                email: "",
                customer_code: "",
                phone: "",
                imageFile: ""
            },
            customer: {},
            village: {},
            customer_edit: {},
            //Pagination
            offset: 15,
            pagination: {},
            per_page: 12,
            oldVal: "",
            search: '',
            //Location
            center: {
                lat: 18.1189434,
                lng: 102.290218,
            },
            markers: [],
            currentPlace: null,
            // package
            free_first_customer: 'no',
            isFirstDiscount: false,
            bottle_giveaway: 'no',
            isaccumulate_bottle: false,
            point_giveaway: 'no',
            isaccumulate_point: false,
            listItem: [
                {
                    id: 1,
                    name: "weeks"
                },
                {
                    id: 2,
                    name: "monthly"
                }
            ],
            selectedItem: '',
            selectedDiscountType: '',
            discount_special:0,
            discountType: [
                {
                    value: "percent",
                    name: "ເປີເຊັນ"
                },
                {
                    value: "cash",
                    name: "ຈຳນວນເງິນ"
                }
            ],

            markerOptions: {
                // eslint-disable-next-line global-require
                url: require('@coms/../../src/assets/vari_pin.png'),
                size: {
                    width: 35,
                    height: 55,
                    f: 'px',
                    b: 'px',
                },
                scaledSize: {
                    width: 35,
                    height: 55,
                    f: 'px',
                    b: 'px',
                },
            },
            timer: null,

            // Service-Zone
            checkedServiceZone: [],
            status: [
                {
                    id: 1,
                    name: "pending",
                },
                {
                    id: 2,
                    name: "approved",
                },
                {
                    id: 3,
                    name: "reject",
                },
            ],
            statusValue: "",
            sources: [
                {
                    id: 1,
                    value: "online",
                    name: "ທາງອອນໄລ",
                },
                {
                    id: 2,
                    value: "booth",
                    name: "ລົງບູດ",
                },
                {
                    id: 3,
                    value: "recommendation",
                    name: "ຄົນຮູ້ຈັກແນະນຳ",
                },
                {
                    id: 4,
                    value: "direct_to_customer",
                    name: "ລົງຫາລູກຄ້າ",
                },
                {
                    id: 5,
                    value: "instant_customer",
                    name: "ລູກຄ້າລະຫວ່າງທາງ",
                },
            ],
            sourceValue: "",
            image: "",
            imageUrl: "",
            deliverable_time: [
                {
                    id: 1,
                    value: "9:00_to_16:00_narmal_time",
                    name: "9:00 ຫາ 16:00",
                },
                {
                    id: 2,
                    value: "18:00_to_20:00_special_time",
                    name: "18:00 ຫາ 20:00",
                },
                {
                    id: 3,
                    value: "weekly",
                    name: "ຈັດສົ່ງທຸກທິດ",
                },
                {
                    id: 4,
                    value: "2_per_weekly",
                    name: "ຈັດສົ່ງ 2 ທິດຄັ້ງ",
                },
                {
                    id: 5,
                    value: "monthly",
                    name: "ຈັກສົ່ງເດືອນລະຄັ້ງ",
                },
                {
                    id: 6,
                    value: "onCall",
                    name: "ໝົດເເລ້ວຈະໂທຫາ",
                },
            ],
            deliverable_time_value: "",

            ruleRole: [(v) => !!v || "Customer type is required"],
            rulePackage: [(v) => !!v || "Package is required"],
            ruleDistrict: [(v) => !!v || "District is required"],
            ruleVillage: [(v) => !!v || "Village is required"],
            ruleSource: [(v) => !!v || "Source is required"],
            ruleTime: [(v) => !!v || "Delivery time is required"],
            ruleRemark: [(v) => !!v || "Remark is required"],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            nameRules: [
                (v) => !!v || "Name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            organizeNameRules: [
                (v) => !!v || "Organization name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            customerCodeRules: [
                (v) => !!v || "Customer code is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            phoneRules: [
                (v) => !!v || "Phone is required",
                (v) =>
                    (v && v.length >= 4 && v.length <= 11) ||
                    "Phone number must be  4 - 11 numbers",
            ],
            addressRules: [
                (v) => !!v || "Name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],

            ///validate package
            rulePer: [(v) => !!v || 'ກາລຸນາເລືອກໄລຍະເວລາ',],
            bondingRules: [
                (v) => !!v || "ກາລຸນາປ້ອນຄ່າມັນຈຳ",
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be  1 - 6 numbers',
            ],
            priceRules: [
                v => !!v || 'ກາລຸນາປ້ອນລາຄາຕໍ່ຕຸກ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            bottleRules: [
                v => !!v || 'ກາລຸນາໃສ່ຈຳນວນຕຸກນ້ຳຂັ້ນຕ່ຳ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            pointRules: [
                v => !!v || 'ກາລຸນາໃສ່ຈຳນວນຄະແນນ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            days: [],
            selectDelivery_day: [],

            toast: {
                value: true,
                color: "success",
                msg: "Success",
            },
            toast_error: {
                value: true,
                color: "error",
                msg: "Something when wrong!",
            },
        };
    },
    methods: {

        onFileChange(e) {
            let input = e.target;
            let file = e.target.files[0];
            this.image = input.files[0];
            this.imageUrl = URL.createObjectURL(file);
            this.server_errors.imageFile = '';
            let formData = new FormData();
            formData.append("imageFile", this.image);
            this.$axios.post('/upload-image', formData)
                .then(res => {
                    if (res.status == 200) {
                        this.image = res.data.fileName;
                        this.saveHomeImage();
                    }
                }).catch(error => {
                this.image = '';
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });

        },
        saveHomeImage() {
            this.$axios.post('/edit-home-image/' + this.customer_edit.id, {
                "image":this.image
            })
                .then(res => {
                    if (res.status == 200) {
                        this.image = '';
                        this.fetchData();
                    }
                }).catch(error => {
                this.image = '';
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });

        },
       async fetchData() {
            this.TableLoading = true;
           this.selectDelivery_day = [];
         await this.$admin.get('filter-customers/' + this.$route.params.id).then(res => {
                setTimeout(() => {
                    this.customer_edit = res.data.customers;
                    this.selectedVillage = this.customer_edit.villageID;
                    this.selectedDiscountType= this.customer_edit.package_detail.discount_special_type;
                    this.discount_special = this.customer_edit.package_detail.discount_special;
                    res.data.customers.delivery_day.map(day => {
                        this.selectDelivery_day.push(day.day_id);
                    })
                    this.TableLoading = false;
                }, 500);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });

            this.$axios
                .get("list-customer-types")
                .then((res) => {
                    this.customerType = res.data.customerTypes;
                    this.TableLoading = false;
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });

            this.$admin
                .get("list-address")
                .then((res) => {
                    this.listVillages = res.data.listVillages;
                    this.listDistricts = res.data.districts;
                    this.filterVillages = res.data.listVillages;
                    this.TableLoading = false;
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });

            this.$admin
                .get("list-packages")
                .then((res) => {
                    setTimeout(() => {
                        this.packages = res.data.listPackages;
                        this.TableLoading = false;
                    }, 100);
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },
        accumulateBottle(value) {
            if (value == "yes") {
                return this.isaccumulate_bottle = true;
            } else if (value == "no") {
                this.customer_edit.package_detail.accumulated_purchase_number = 0;
                this.customer_edit.package_detail.accumulated_purchase_number = 0;
                this.customer.accumulated_purchase_number = 0;
                this.customer.accumulated_purchase_number = 0;
                return this.isaccumulate_bottle = false;

            }
        },
        accumulatePoint(value) {
            if (value == "yes") {
                return this.isaccumulate_point = true;
            } else if (value == "no") {
                this.customer_edit.package_detail.accumulated_purchase_point = 0;
                this.customer_edit.package_detail.accumulated_purchase_point = 0;
                this.customer.accumulated_purchase_point = 0;
                this.customer.accumulated_purchase_point = 0;
                return this.isaccumulate_point = false;

            }
        },
        //First Sale Discount
        firstSaleDiscount(value) {
            if (value == "yes") {
                return this.isFirstDiscount = true;
            } else if (value == "no") {
                this.customer_edit.package_detail.discount_first_customer = 0;
                this.customer.discount_first_customer = 0;
                return this.isFirstDiscount = false;

            }
        },

        routeBack() {
            this.$router.push({name: 'Customer'});
        },

        UpdateItem() {
            let formData = new FormData();
            formData.append('customer_code', this.customer_edit.customer_code);
            formData.append('name', this.customer_edit.name);
            formData.append('surname', this.customer_edit.surname);
            formData.append('organization_name', this.customer_edit.organization_name);
            formData.append('phone', this.customer_edit.phone);
            formData.append('source', this.customer_edit.source);
            formData.append('deliverable_time', this.customer_edit.deliverable_time);
            formData.append('packageId', this.customer_edit.packageId);
            formData.append('status', this.customer_edit.status);
            formData.append('customer_type_id', this.customer_edit.customer_type_id);
            formData.append('village_id', this.customer_edit.villageID);
            formData.append('address', this.customer_edit.address);
            formData.append('latitude', this.customer_edit.latitude);
            formData.append('longitude', this.customer_edit.longitude);
            formData.append('discount_special_type', this.selectedDiscountType);
            formData.append('discount_special', this.discount_special);
            //if no package
            if (this.customer_edit.package_detail == null) {
                formData.append('bonding_fees', this.customer.bonding_fees);
                formData.append('price', this.customer.price);
                formData.append('free_first_customer', this.free_first_customer);
                formData.append('discount_first_customer', this.customer.discount_first_customer);

                formData.append('bottle_giveaway', this.bottle_giveaway);
                formData.append('accumulated_purchase_number', this.customer.accumulated_purchase_number);
                formData.append('discount', this.customer.discount);

                formData.append('point_giveaway', this.point_giveaway);
                formData.append('accumulated_purchase_point', this.customer.accumulated_purchase_point);

            }

            if (this.customer_edit.package_detail !== null) {
                formData.append('bonding_fees', this.customer_edit.package_detail.bonding_fees);
                formData.append('price', this.customer_edit.package_detail.price);
                formData.append('free_first_customer', this.customer_edit.package_detail.free_first_customer);
                formData.append('discount_first_customer', this.customer_edit.package_detail.discount_first_customer);

                formData.append('bottle_giveaway', this.customer_edit.package_detail.bottle_giveaway);
                formData.append('accumulated_purchase_number', this.customer_edit.package_detail.accumulated_purchase_number);
                formData.append('discount', this.customer_edit.package_detail.discount);

                formData.append('point_giveaway', this.customer_edit.package_detail.point_giveaway);
                formData.append('accumulated_purchase_point', this.customer_edit.package_detail.accumulated_purchase_point);
            }
            this.selectDelivery_day.map((item) => {
                formData.append("delivery_day[]", item);
            });


            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin
                    .post("edit-customer/" + this.customer_edit.id, formData)
                    .then((res) => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.customer_edit = {};
                                this.$store.commit("Toast_State", this.toast);
                                this.$router
                                    .push({ name: "Customer", query: { tab: "customer" } })
                            }, 300);
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast_error);
                        if (error.response.status == 422) {
                            let obj = error.response.data.errors;
                            for (let [key, customer] of Object.entries(obj)) {
                                this.server_errors[key] = customer[0];
                            }
                        }
                    });
            }
        },

        FilterVillages(districtId) {
            const result_checking = this.filterVillages.filter((item) => {
                return item.district_id == districtId;
            });
            this.listVillages = result_checking;
            this.selectedVillage = {...this.listVillages[0]};
        },

        closeCustomer(id) {
            this.closeId = id;
            this.modalConfirmClose = true;
        },
        closeCustomerConfirm() {
            this.loading = true;
            this.$admin
                .post("close-customer-service/" + this.closeId, {remark: this.remark})
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.fetchData();
                            this.modalConfirmClose = false;
                            this.loading = false;
                            this.remark = "";
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                })
                .catch(() => {
                    this.fetchData();
                    this.$store.commit("Toast_State", this.toast_error);
                    this.modalConfirmClose = false;
                    this.loading = false;
                });

        },

        RefreshVillage() {
            this.$admin
                .get("list-address")
                .then((res) => {
                    this.filterVillages = res.data.listVillages;
                    this.TableLoading = false;
                    this.FilterVillages(this.selectedDistrict);
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        OpenAddVillage() {
            this.$store.commit("modalAdd2_State", true);
        },

        CloseAddVillage() {
            this.$store.commit("modalAdd2_State", false);
        },


        //Set Googlemap Api
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
            this.customer_edit.latitude = this.center.lat;
            this.customer_edit.longitude = this.center.lng;
        },
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({position: marker});
                this.center = marker;
                this.animateMarker();
            } else {
                // const marker = {
                //     lat: this.center.lat,
                //     lng: this.center.lng,
                // };
                const marker = {
                    lat: parseFloat(this.customer_edit.latitude),
                    lng: parseFloat(this.customer_edit.longitude),
                };
                this.markers.push({position: marker});
                this.markers.push({position: marker});
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                // this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                // const obj = this.$refs.markers[0].$markerObject;
                // if (obj) {
                //     obj.setAnimation(1);
                //     clearTimeout(this.timer);
                //     this.timer = setTimeout(() => {
                //         obj.setAnimation(null);
                //     }, 800);
                // }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });

        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },
        getCenter() {
            if (this.customer_edit.latitude) {
                const latlng = {
                    lat: parseFloat(this.customer_edit.latitude),
                    lng: parseFloat(this.customer_edit.longitude),
                };
                return latlng;
            } else {
                return this.center;
            }
        },
        getMarkers(data) {
            if (data.latitude != null) {
                return {
                    lat: parseFloat(data.latitude),
                    lng: parseFloat(data.longitude),
                };
            } else {
                return this.center;
            }
        },

        async fetchDay() {
            await this.$axios
                .get("day")
                .then((res) => {
                    this.days = res.data.data;
                })
                .catch(() => {
                });
        },
    },
    watch: {
        "customer_edit.district_id": function (dist_id) {
            this.FilterVillages(dist_id);
        },

        'customer_edit.price': function () {
            this.server_errors.price = '';
        },
        'customer_edit.package_detail.bottle_giveaway': function (value) {
            this.accumulateBottle(value);
        },
        'customer_edit.package_detail.point_giveaway': function (value) {
            this.accumulatePoint(value);
        },
        "customer.free_first_customer": function (value) {
            this.firstSaleDiscount(value);
        },
        "customer_edit.package_detail.free_first_customer": function (value) {
            this.firstSaleDiscount(value);
        },

        "customer_edit.email": function () {
            this.server_errors.email = "";
        },
        "customer_edit.phone": function () {
            this.server_errors.phone = "";
        },
        "customer_edit.customer_code": function () {
            this.server_errors.customer_code = "";
        },
    },

    created() {
        this.fetchData();
        this.fetchDay();
        (this.selectDelivery_day);
    },
    mounted() {
        this.geolocate();
    },
};
