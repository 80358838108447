<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Update Customer</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>

            <v-row>
              <v-col>
                <div class="field">
                  <div class="file is-large is-boxed">
                    <label class="file-label">
                      <input
                          @change="onFileChange"
                          class="file-input input-file-image"
                          type="file"
                          name="image"
                          accept="image/*"
                          ref="image"
                          hidden
                      />
                      <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              ເລືອກຮູບເຮືອນ
                            </span>
                          </span>
                    </label>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="errors">
                  {{ server_errors.imageFile }}
                </p>
              </v-col>
            </v-row>
            <v-row>
<!--              <v-col class="mt-5" v-if="image">-->
<!--                <v-avatar class="avatar rounded" size="94px">-->
<!--                  <img :src="imageUrl" alt=""/>-->
<!--                </v-avatar>-->
<!--              </v-col>-->
              <v-col class="mt-5">
                <v-avatar class="avatar rounded" size="94px">
                  <img v-if="customer_edit.home_image" :src="customer_edit.home_image"/>
                </v-avatar>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ລະຫັດລູກຄ້າ *"
                    required
                    v-model="customer_edit.customer_code"
                    :rules="customerCodeRules"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.customer_code }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ຜູ້ຮັບຜິດຊອບ"
                    required
                    v-model="customer_edit.organization_name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ຊື່ *"
                    required
                    v-model="customer_edit.name"
                    :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ນາມສະກຸນ"
                    v-model="customer_edit.surname"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ເບີໂທ *"
                    required
                    v-model="customer_edit.phone"
                    :rules="phoneRules"
                    type="number"
                    class="input-number"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.phone }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Email"
                    v-model="customer_edit.email"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.email }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ຊື່ບັນຊີທະນາຄານ"
                    v-model="customer_edit.account_name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ເລກບັນຊີ"
                    v-model="customer_edit.account_number"
                    type="number"
                    class="input-number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                    required
                    v-model="customer_edit.customer_type_id"
                    :items="customerType"
                    item-text="name"
                    item-value="id"
                    label="ເລືອກປະເພດລູກຄ້າ *"
                    :rules="ruleRole"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                    required
                    v-model="customer_edit.packageId"
                    :items="packages"
                    item-text="name"
                    item-value="id"
                    label="ປະເພດແພັກເກດ *"
                    :rules="rulePackage"
                >
                </v-select>
              </v-col>
            </v-row>

            <div v-if="customer_edit.package_detail">
              <v-row>
                <v-col cols="12">
                  <v-label>ຟຣີສັ່ງຊື້ນ້ຳຄັ້ງທຳອິດບໍ່ ?</v-label>
                  <v-radio-group v-model="customer_edit.package_detail.free_first_customer" row>
                    <v-radio label="Yes" value="yes"  @change="firstSaleDiscount('yes')"></v-radio>
                    <v-radio label="No" value="no" @change="firstSaleDiscount('no')"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-col cols="12" v-if="isFirstDiscount">
                    <v-text-field
                        label="ສ່ວນຫຼູດເປີເຊັນ*"
                        required
                        v-model="customer_edit.package_detail.discount_first_customer"
                        type="number"
                        class="input-number"
                        :maxlength="3"
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-label>ຟຣີສະສົມຈຳນວນເງິນ ?</v-label>
                  <v-radio-group v-model="customer_edit.package_detail.bottle_giveaway" row>
                    <v-radio
                        label="Yes"
                        value="yes"
                        @change="accumulateBottle('yes')"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="no"
                        @change="accumulateBottle('no')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" v-if="isaccumulate_bottle">
                  <v-text-field
                      label="ຈຳນວນເງິນສະສົມເພື່ອແລກສ່ວນຫຼຸດ*"
                      required
                      v-model="customer_edit.package_detail.accumulated_purchase_number"
                      type="number"
                      class="input-number"
                      :rules="bottleRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="isaccumulate_bottle">
                  <v-text-field
                      label="ສ່ວນຫຼຸດເປັນເງິນ*"
                      required
                      v-model="customer_edit.package_detail.discount"
                      type="number"
                      class="input-number"
                      :rules="bottleRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-label>ໄດ້ຄະແນນສະສົມບໍ່ ?</v-label>
                  <v-radio-group v-model="customer_edit.package_detail.point_giveaway" row>
                    <v-radio
                        label="Yes"
                        value="yes"
                        @change="accumulatePoint('yes')"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="no"
                        @change="accumulatePoint('no')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="isaccumulate_point">
                  <v-text-field
                      label="ຈຳນວນເງິນສະສົມຕໍ່ຄະແນນ *"
                      required
                      v-model="customer_edit.package_detail.accumulated_purchase_point"
                      type="number"
                      class="input-number"
                      :rules="pointRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      label="ຄ່າມັດຈຳຕໍ່ຕຸກ *"
                      required
                      v-model="customer_edit.package_detail.bonding_fees"
                      :rules="bondingRules"
                      type="number"
                      class="input-number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      label="ລາຄາຕໍ່ຕຸກ *"
                      required
                      v-model="customer_edit.package_detail.price"
                      :rules="priceRules"
                      type="number"
                      class="input-number"
                  ></v-text-field>
                  <p class="errors">
                    {{ server_errors.price }}
                  </p>
                </v-col>
              </v-row>

            </div>
<!--            Add new package if null-->
            <div v-else>
              <!-- Package-->
              <v-row>
                <v-col cols="12">
                  <v-label>ຟຣີສັ່ງຊື້ນ້ຳຄັ້ງທຳອິດບໍ່ ?</v-label>
                  <v-radio-group v-model="free_first_customer" row>
                    <v-radio label="Yes" value="yes"  @change="firstSaleDiscount('yes')"></v-radio>
                    <v-radio label="No" value="no" @change="firstSaleDiscount('no')"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-col cols="12" v-if="isFirstDiscount">
                    <v-text-field
                        label="ສ່ວນຫຼູດເປີເຊັນ*"
                        required
                        v-model="customer.discount_first_customer"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-label>ຟຣີສະສົມຈຳນວນເງິນ ?</v-label>
                  <v-radio-group v-model="bottle_giveaway" row>
                    <v-radio
                        label="Yes"
                        value="yes"
                        @change="accumulateBottle('yes')"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="no"
                        @change="accumulateBottle('no')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" v-if="isaccumulate_bottle">
                  <v-text-field
                      label="ຈຳນວນເງິນສະສົມເພື່ອແລກສ່ວນຫຼຸດ*"
                      required
                      v-model="customer.accumulated_purchase_number"
                      type="number"
                      class="input-number"
                      :rules="bottleRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="isaccumulate_bottle">
                  <v-text-field
                      label="ສ່ວນຫຼຸດເປັນເງິນ*"
                      required
                      v-model="customer.discount"
                      type="number"
                      class="input-number"
                      :rules="bottleRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-label>ໄດ້ຄະແນນສະສົມບໍ່ ?</v-label>
                  <v-radio-group v-model="point_giveaway" row>
                    <v-radio
                        label="Yes"
                        value="yes"
                        @change="accumulatePoint('yes')"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="no"
                        @change="accumulatePoint('no')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="isaccumulate_point">
                  <v-text-field
                      label="ຈຳນວນເງິນສະສົມຕໍ່ຄະແນນ *"
                      required
                      v-model="customer.accumulated_purchase_point"
                      type="number"
                      class="input-number"
                      :rules="pointRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      label="ຄ່າມັດຈຳຕໍ່ຕຸກ *"
                      required
                      v-model="customer.bonding_fees"
                      :rules="bondingRules"
                      type="number"
                      class="input-number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      label="ລາຄາຕໍ່ຕຸກ *"
                      required
                      v-model="customer.price"
                      :rules="priceRules"
                      type="number"
                      class="input-number"
                  ></v-text-field>
                  <p class="errors">
                    {{ server_errors.price }}
                  </p>
                </v-col>
              </v-row>
            </div>
            <!-- Discount-->
            <v-row>
              <v-col cols="6">
                <v-select
                    label="ປະເພດສ່ວນຫຼຸດ *"
                    required
                    v-model="selectedDiscountType"
                    :items="discountType"
                    item-value="value"
                    item-text="name"
                    class="input-number"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ຈຳນວນສ່ວນຫຼຸດ"
                    required
                    v-model="discount_special"
                    type="number"
                    class="input-number"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.discount_special }}
                </p>
              </v-col>
            </v-row>

            <!-- Package-->

            <v-row>
              <v-col cols>
                <v-select
                    required
                    v-if="customer_edit.delivery_day"
                    v-model="selectDelivery_day"
                    :items="days"
                    item-text="name"
                    item-value="id"
                    label="ມຶ້ຈັດສົ່ງ"
                    multiple
                >
                </v-select>
              </v-col>
              <v-col cols>
                <v-select
                    required
                    v-model="customer_edit.source"
                    :items="sources"
                    item-text="name"
                    item-value="value"
                    label="ທີ່ມາຂອງລູກຄ້າ *"
                    :rules="ruleSource"
                >
                </v-select>
              </v-col>
              <v-col cols>
                <v-select
                    required
                    v-model="customer_edit.deliverable_time"
                    :items="deliverable_time"
                    item-text="name"
                    item-value="value"
                    label="ເວລາທີ່ສະດວກສົ່ງ *"
                    :rules="ruleTime"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                    dense
                    required
                    v-model="customer_edit.district_id"
                    :items="listDistricts"
                    item-text="name"
                    item-value="id"
                    label="ເລືອກເມືອງ *"
                    :rules="ruleDistrict"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                    dense
                    required
                    v-model="customer_edit.villageID"
                    :items="listVillages"
                    item-text="name"
                    item-value="id"
                    label="ບ້ານ *"
                    :rules="ruleVillage"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn
                    class="ml-2 mr-0"
                    fab
                    small
                    dark
                    color="btn-primary"
                    @click="OpenAddVillage()"
                >
                  <v-icon dark> mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ຈຸດສັງເກດ (ຮ່ອມ,ຖະໜົນ)"
                    required
                    v-model="customer_edit.address"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                    required
                    v-model="customer_edit.status"
                    :items="status"
                    item-text="name"
                    item-value="name"
                    label="ເລືອກສະຖານະ*"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="Latitude"
                    v-model="customer_edit.latitude"
                    type="number"
                    class="input-number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Longitude"
                    v-model="customer_edit.longitude"
                    type="number"
                    class="input-number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <gmap-autocomplete
                    ref="searchInput"
                    class="input text-field"
                    placeholder="ຄົ້ນຫາເເຜນທີ່..."
                    @place_changed="setPlace"
                    :options="{
                        fields: ['geometry', 'formatted_address', 'name'],
                      }"
                >
                </gmap-autocomplete>
                <span class="horizontal-divider"></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-4">
                <GmapMap
                    :center="getCenter()"
                    :zoom="16"
                    style="width: 100%; height: 450px"
                >
                  <GmapMarker
                      :position="getMarkers(customer_edit)"
                      :draggable="true"
                      @dragend="onLocation"
                      :icon="markerOptions"
                      :animation="2"
                      ref="markers"
                  />
                </GmapMap>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="routeBack()">
            Back
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="UpdateItem()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import edit_customer from "../../../mixins/customer/edit_customer";

export default {
  mixins: [edit_customer],
  metaInfo() {
    return {
      title: `ແກ້ໄຂ - ລູກຄ້າ`,
      meta: [
        { name: 'description', content: 'ລູກຄ້າ - ແກ້ໄຂ'},
      ]
    }
  },
};
</script>